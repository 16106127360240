import { createRouter, createWebHashHistory } from 'vue-router'

import { Cache } from './services/cache/Cache.ts'
import { onImportError } from './services/updateChecker.ts'
import { userpilotClient } from './services/UserpilotClient.ts'

const AcceptUserInvitation = () => import('./components/AcceptUserInvitation.vue').catch(onImportError)
const Auth = () => import('./components/auth/Auth.vue').catch(onImportError)
const BillingDetails = () => import('./components/BillingDetails.vue').catch(onImportError)
const BillingInvoices = () => import('./components/BillingInvoices.vue').catch(onImportError)
const BillingNotifications = () => import('./components/BillingNotifications.vue').catch(onImportError)
const BillingPaymentMethod = () => import('./components/BillingPaymentMethod.vue').catch(onImportError)
const ChooseOrganization = () => import('./components/auth/ChooseOrganization.vue').catch(onImportError)
const CustomPackDetail = () => import('./components/OrganizationSources/CustomPackDetail.vue').catch(onImportError)
const DialogInvalidOrganization = () => import('./components/dialogs/DialogInvalidOrganization.vue').catch(onImportError)
const ExternalOnboardingForm = () => import('./components/ExternalOnboardingForm.vue').catch(onImportError)
const ExternalOnboardingForms = () => import('./components/ExternalOnboardingForms.vue').catch(onImportError)
const Groups = () => import('./components/Groups.vue').catch(onImportError)
const InactiveOrganization = () => import('./components/InactiveOrganization.vue').catch(onImportError)
const Login = () => import('./components/auth/Login.vue').catch(onImportError)
const OnboardingFormExternalAuthentication = () => import('./components/OnboardingFormExternalAuthentication.vue').catch(onImportError)
const OrganizationImportUsers = () => import('./components/OrganizationImportUsers.vue').catch(onImportError)
const OrganizationIntegrations = () => import('./components/OrganizationIntegrations.vue').catch(onImportError)
const OrganizationPolicies = () => import('./components/OrganizationPolicies.vue').catch(onImportError)
const OrganizationProfile = () => import('./components/OrganizationProfile.vue').catch(onImportError)
const OrganizationRiskClassification = () => import('./components/OrganizationRiskClassification.vue').catch(onImportError)
const OrganizationServices = () => import('./components/OrganizationServices.vue').catch(onImportError)
const PlanManagementComplete = () => import('./components/PlanManagementComplete.vue').catch(onImportError)
const Reset = () => import('./components/auth/Reset.vue').catch(onImportError)
const ResetEmail = () => import('./components/auth/ResetEmail.vue').catch(onImportError)
const Response = () => import('./components/auth/Response.vue').catch(onImportError)
const ScreeningCaseWorkflow = () => import('./components/ScreeningCaseWorkflow.vue').catch(onImportError)
const ScreeningImportCases = () => import('./components/ScreeningImportCases.vue').catch(onImportError)
const ScreeningSearchPolicies = () => import('./components/ScreeningSearchPolicies.vue').catch(onImportError)
const StatisticsCaseCharacteristics = () => import('./components/StatisticsCaseCharacteristics.vue').catch(onImportError)
const StatisticsMonitoring = () => import('./components/StatisticsMonitoring.vue').catch(onImportError)
const StatisticsReports = () => import('./components/StatisticsReports.vue').catch(onImportError)
const StatisticsTeamActivity = () => import('./components/StatisticsTeamActivity.vue').catch(onImportError)
const StatisticsUnresolvedStatus = () => import('./components/StatisticsUnresolvedStatus.vue').catch(onImportError)
const Users = () => import('./components/Users.vue').catch(onImportError)
const ViewAccount = () => import('./components/ViewAccount.vue').catch(onImportError)
const ViewConfiguration = () => import('./components/ViewConfiguration.vue').catch(onImportError)
const ViewLanguages = () => import('./components/ViewLanguages.vue').catch(onImportError)
const ViewAPITokens = () => import('./components/ViewAPITokens.vue').catch(onImportError)
const ViewApp = () => import('./components/ViewApp.vue').catch(onImportError)
const ViewBilling = () => import('./components/ViewBilling.vue').catch(onImportError)
const ViewBlue = () => import('./components/ViewBlue.vue').catch(onImportError)
const ViewCase = () => import('./components/CaseDashboard/ViewCase.vue').catch(onImportError)
const ViewCases = () => import('./components/ViewCases.vue').catch(onImportError)
const ViewClient = () => import('./components/Clients/ViewClient.vue').catch(onImportError)
const ViewClients = () => import('./components/Clients/ViewClients.vue').catch(onImportError)
const ViewDataDirectory = () => import('./components/ViewDataDirectory.vue').catch(onImportError)
const ViewHome = () => import('./components/ViewHome.vue').catch(onImportError)
const ViewOnboarding = () => import('./components/ViewOnboarding.vue').catch(onImportError)
const ViewOnboardingClient = () => import('./components/Clients/ViewOnboardingClient.vue').catch(onImportError)
const ViewOnboardingClients = () => import('./components/Clients/ViewOnboardingClients.vue').catch(onImportError)
const ViewOnboardingExternal = () => import('./components/ViewOnboardingExternal.vue').catch(onImportError)
const ViewOnboardingForm = () => import('./components/ViewOnboardingForm.vue').catch(onImportError)
const ViewOnboardingFormTemplate = () => import('./components/ViewOnboardingFormTemplate.vue').catch(onImportError)
const ViewOnboardingHome = () => import('./components/ViewOnboardingHome.vue').catch(onImportError)
const ViewOneTimeCase = () => import('./components/ViewOneTimeCase.vue').catch(onImportError)
const ViewOrganization = () => import('./components/ViewOrganization.vue').catch(onImportError)
const ViewRateEmail = () => import('./components/ViewRateEmail.vue').catch(onImportError)
const ViewScreening = () => import('./components/ViewScreening.vue').catch(onImportError)
const ViewSignup = () => import('./components/ViewSignup.vue').catch(onImportError)
const ViewStatistics = () => import('./components/ViewStatistics.vue').catch(onImportError)

// Administration routes
const AdministrationAuthorization = () => import('./components/AdministrationAuthorization.vue').catch(onImportError)
const AdministrationBillingManagement = () => import('./components/AdministrationBillingManagement.vue').catch(onImportError)
const AdministrationCommands = () => import('./components/AdministrationCommands.vue').catch(onImportError)
const AdministrationEngagement = () => import('./components/AdministrationEngagement.vue').catch(onImportError)
const AdministrationLogs = () => import('./components/AdministrationLogs.vue').catch(onImportError)
const AdministrationOrganizationBilling = () => import('./components/AdministrationOrganizationBilling.vue').catch(onImportError)
const AdministrationOrganizationProfile = () => import('./components/AdministrationOrganizationProfile.vue').catch(onImportError)
const AdministrationUsage = () => import('./components/AdministrationUsage.vue').catch(onImportError)
const ViewAdministrationCreateOrganizationInvitation = () => import('./components/ViewAdministrationCreateOrganizationInvitation.vue').catch(onImportError)
const ViewAdministrationHome = () => import('./components/ViewAdministrationHome.vue').catch(onImportError)
const ViewAdministrationOrganization = () => import('./components/ViewAdministrationOrganization.vue').catch(onImportError)
const ViewAdministrationOrganizations = () => import('./components/ViewAdministrationOrganizations.vue').catch(onImportError)
const ViewAdministrationUsers = () => import('./components/ViewAdministrationUsers.vue').catch(onImportError)

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      // The Auth component renders a view which is used on authentication pages like login or reset password.
      // These are only accessible by guest users.
      path: '/login',
      component: Auth,
      children: [
        { path: '/login', component: Login, meta: { title: 'Sign in', guest: true } },
        { path: '/password/reset', component: ResetEmail, meta: { title: 'Reset your password', guest: true } },
        { path: '/password/reset/:token', component: Reset, meta: { title: 'Reset your password', guest: true } },
        { path: '/response', component: Response, meta: { title: 'Response', guest: true } },
      ],
    },

    { path: '/sanction-screening', redirect: () => ({ path: '/signup' }), meta: { guest: true } },

    {
      path: '/signup',
      component: ViewSignup,
      children: [{ path: '/signup', component: ViewSignup, meta: { title: 'Signup for Pascal', guest: true } }],
    },

    {
      // ViewBlue shows the content in a specific layout / style. Any component can be added here.
      path: '/view-blue',
      component: ViewBlue,
      children: [
        { path: '/signup-complete', component: PlanManagementComplete, meta: { title: 'Signup for Pascal', guest: true } },
        { path: '/inactive-organisation', component: InactiveOrganization, meta: { title: 'Inactive organisation' } },
        { path: '/choose-organisation', component: ChooseOrganization, meta: { title: 'Choose organisation' } },
        {
          path: '/user-invitations/:id',
          component: AcceptUserInvitation,
          meta: { title: 'User invitation', guest: true, allowSignedIn: true },
          props: (route) => ({
            invitationId: Number(route.params.id),
            invitationToken: route.query.invitation_token,
          }),
        },
        {
          path: '/onboarding-form-external-authentication',
          component: OnboardingFormExternalAuthentication,
          meta: { title: 'Authentication', guest: true, allowSignedIn: true },
          props: (route) => ({ email: route.query.email ?? '' }),
        },
        {
          path: '/invalid-organization/:organizationId',
          component: DialogInvalidOrganization,
          props: (route) => ({ visible: true, organizationId: Number(route.params.organizationId) }),
        },
      ],
    },

    {
      path: '/external-view',
      component: ViewOnboardingExternal,
      children: [
        {
          path: '/external-onboarding-forms',
          component: ExternalOnboardingForms,
          meta: { title: 'Onboarding' },
        },
        {
          path: '/external-onboarding-forms/:id',
          component: ExternalOnboardingForm,
          meta: { title: 'Onboarding form' },
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },

    {
      path: '/rate-email',
      component: ViewRateEmail,
      meta: { title: 'Email feedback' },
      props: (route) => ({
        emailType: route.query.email_type,
        liked: route.query.liked === '1',
      }),
    },

    { path: '/one-time-case', component: ViewOneTimeCase },

    {
      // ViewApp contains routes that are accessed by a signed in user in a valid organization.
      path: '/',
      meta: { organizationRequired: true },
      name: 'root',
      component: ViewApp,
      children: [
        { path: '/', component: ViewHome, meta: { title: 'Home' } },
        { path: '/client-onboarding/home', component: ViewOnboardingHome, meta: { title: 'Home' } },
        {
          path: '/client-onboarding/form-templates/:id',
          component: ViewOnboardingFormTemplate,
          meta: { title: 'Form template' },
          props: (route) => ({ id: Number(route.params.id) }),
        },
        { path: '/clients', component: ViewClients, meta: { title: 'Clients' } },
        { path: '/clients/:id', component: ViewClient, name: 'clients.id' },
        {
          path: '/client-onboarding/onboarding-forms/:id',
          component: ViewOnboardingForm,
          meta: { title: 'Onboarding form' },
          props: (route) => ({ id: Number(route.params.id) }),
        },
        { path: '/client-onboarding/clients', component: ViewOnboardingClients, meta: { title: 'Clients' } },
        { path: '/client-onboarding/clients/:id', component: ViewOnboardingClient, name: 'client-onboarding.clients.id' },
        { path: '/cases', component: ViewCases, meta: { title: 'Cases' } },
        {
          path: '/cases/:uuid',
          component: ViewCase,
          props: (route) => ({ dialogResolveVisible: route.query.resolve ? true : false }),
        },
        {
          path: '/:type(.*)*-check/:uuid',
          redirect: (to) => ({ path: `/cases/${to.params.uuid}` }),
        },
        {
          path: '/statistics',
          component: ViewStatistics,
          children: [
            { path: '', redirect: (to) => `${to.path}/unresolved-status` },
            { path: 'unresolved-status', component: StatisticsUnresolvedStatus, meta: { title: 'Unresolved status' } },
            { path: 'team-activity', component: StatisticsTeamActivity, meta: { title: 'Team activity', permission: 'analytics.view' } },
            { path: 'case-characteristics', component: StatisticsCaseCharacteristics, meta: { title: 'Case characteristics' } },

            { path: 'reports', component: StatisticsReports, meta: { title: 'Reports' } },
            { path: 'monitoring', component: StatisticsMonitoring, meta: { title: 'Monitoring' } },
            { path: 'monitoring/:id', component: StatisticsMonitoring, meta: { title: 'Monitoring' } },
          ],
        },
        {
          path: '/settings',
          children: [
            { path: '', redirect: (to) => `${to.path}/account` },
            { path: 'account', component: ViewAccount, meta: { title: 'Account' } },
            { path: 'api-tokens', component: ViewAPITokens, meta: { title: 'Account', permission: 'cases.import' } },
            { path: 'configuration', component: ViewConfiguration, meta: { title: 'Configuration' } },
            { path: 'languages', component: ViewLanguages, meta: { title: 'Languages' } },
          ],
        },
        {
          path: '/organizations/:organizationId',
          children: [
            {
              path: '',
              component: ViewOrganization,
              meta: { title: 'Organisation' },
              children: [
                { path: '', redirect: (to) => `${to.path}/profile`, name: 'organizations.id' },
                { path: 'profile', component: OrganizationProfile, meta: { title: 'Profile' }, name: 'organization-profile' },
                { path: 'users', component: Users, meta: { title: 'Users' } },
                { path: 'groups', component: Groups, meta: { title: 'Groups' } },
                { path: 'services', component: OrganizationServices, meta: { title: 'Services' } },
                {
                  path: 'risk-classification',
                  component: OrganizationRiskClassification,
                  meta: { title: 'Risk Classification', service: 'riskClassification', permission: 'organizationRiskConfigs.update' },
                },
                { path: 'import-users', component: OrganizationImportUsers, meta: { title: 'Import Users', permission: 'users.create' } },
                { path: 'integrations', component: OrganizationIntegrations, meta: { title: 'Integrations', permission: 'organizationIntegrations.view' } },
                { path: 'policies', component: OrganizationPolicies, meta: { title: 'Policies', permission: 'organizationPolicies.update' } },
              ],
            },
            {
              path: 'screening',
              component: ViewScreening,
              children: [
                { path: '', redirect: (to) => `${to.path}/search-policies` },
                { path: 'search-policies', component: ScreeningSearchPolicies, meta: { title: 'Search Policies' } },
                { path: 'case-workflow', component: ScreeningCaseWorkflow, meta: { title: 'Case Workflow' } },
                { path: 'import-cases', component: ScreeningImportCases, meta: { title: 'Import Cases' } },
              ],
            },
            {
              path: 'onboarding',
              component: ViewOnboarding,
              meta: { title: 'Onboarding' },
            },
            {
              path: 'data-directory',
              component: ViewDataDirectory,
              meta: { permission: 'organizationSources.update', service: 'privateWatchlist', title: 'Data Directory' },
            },
            {
              path: 'data-directory/:pack',
              component: CustomPackDetail,
              meta: { title: 'Data Directory', service: 'privateWatchlist', permission: 'organizationSources.update' },
            },
            {
              path: 'billing',
              component: ViewBilling,
              meta: {
                title: 'Billing',
                permission: 'billingSettings.edit',
                visible: () => stores.organization.type !== 'free' || !stores.organization.microsoft_marketplace_subscription_id,
              },
              children: [
                { path: '', redirect: (to) => `${to.path}/billing-details` },
                { path: 'billing-details', component: BillingDetails, meta: { title: 'Billing Details' } },
                { path: 'payment-methods', component: BillingPaymentMethod, meta: { title: 'Payment Method' } },
                { path: 'invoices', component: BillingInvoices, meta: { title: 'Invoices' } },
                { path: 'notifications', component: BillingNotifications, meta: { title: 'Billing Notifications' } },
              ],
            },
          ],
        },

        // Administration routes
        {
          path: '/administration/home',
          component: ViewAdministrationHome,
          meta: { permission: 'administrations.view', title: 'Administration', notRoles: ['Financial'] },
          children: [
            { path: '', redirect: (to) => (hasRole('Financial') ? `${to.path}/billing-management` : `${to.path}/engagement`), name: 'administration' },
            { path: 'billing-management', component: AdministrationBillingManagement, meta: { permission: 'administrations.view', title: 'Billing', notRoles: [] } },
            { path: 'engagement', name: 'engagement', component: AdministrationEngagement, meta: { permission: 'administrations.view', title: 'Engagement' } },
            { path: 'usage', name: 'usage', component: AdministrationUsage, meta: { permission: 'administrations.view', title: 'Usage' } },
            { path: 'authorization', component: AdministrationAuthorization, meta: { permission: 'administrations.view', title: 'Authorization' } },
            { path: 'commands', component: AdministrationCommands, meta: { permission: 'administrations.view', title: 'Commands' } },
            { path: 'logs', component: AdministrationLogs, meta: { permission: 'administrations.view', title: 'Logs' } },
          ],
        },
        {
          path: '/administration/organizations',
          component: ViewAdministrationOrganizations,
          meta: { permission: 'administrations.view', title: 'Organisations', notRoles: ['Financial'] },
        },
        {
          path: '/administration/organizations/:organizationId',
          component: ViewAdministrationOrganization,
          meta: { permission: 'administrations.view', title: 'Organisation', notRoles: ['Financial'] },
          children: [
            { path: '', redirect: (to) => `${to.path}/profile`, name: 'organization' },
            { path: 'profile', component: AdministrationOrganizationProfile, meta: { title: 'Organization' } },
            { path: 'engagement', component: AdministrationEngagement, meta: { title: 'Engagement' }, props: (route) => ({ organizationId: Number(route.params.organizationId) }) },
            { path: 'usage', component: AdministrationUsage, meta: { title: 'Usage' }, props: (route) => ({ organizationId: route.params.organizationId }) },
            { path: 'billing', component: AdministrationOrganizationBilling, meta: { title: 'Billing' } },
          ],
        },
        {
          path: '/organization-invitation/create',
          component: ViewAdministrationCreateOrganizationInvitation,
          meta: { permission: 'administrations.view', title: 'Create organisation invitation token', notRoles: ['Financial'] },
        },
        { path: '/administration/clients', component: ViewClients, meta: { permission: 'administrations.view', title: 'Clients', notRoles: ['Financial'] } },
        { path: '/administration/cases', component: ViewCases, meta: { permission: 'administrations.view', title: 'Cases', notRoles: ['Financial'] } },
        {
          path: '/administration/cases/:uuid',
          component: ViewCase,
          props: (route) => ({ dialogResolveVisible: route.query.resolve ? true : false }),
          meta: { permission: 'administrations.view', notRoles: ['Financial'] },
        },
        { path: '/administration/users', component: ViewAdministrationUsers, meta: { permission: 'administrations.view', title: 'Users', notRoles: ['Financial'] } },
      ],
    },

    // redirect not found to home page.
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ],
})

export default router

export const route = router.currentRoute

export const history = ref<string[]>([])

export function createRouterGuards() {
  router.beforeEach(async (to, from, next) => {
    // If we intentionally triggered this, we can remove the query param that triggered this.
    const triggerRouterGuards = to.query.triggerRouterGuards
    delete to.query.triggerRouterGuards

    if (isOneTimeCase() && !to.fullPath.includes('one-time-case')) {
      // One time case users can only access the one time case page.
      return next(Error('Unauthorized route'))
    }

    if (!stores.user.authenticated && !to.meta.guest) {
      // Non authenticated users that go to non-guest routes need to sign up.
      return next({ path: '/login', query: { next: to.fullPath } })
    } else if (stores.user.authenticated && to.meta.guest && !to.meta.allowSignedIn) {
      // Authenticated users cannot access guest routes.
      return next('/')
    }

    // When a user opens a link that needs to be in a certain organization,
    // we can handle the switching to that organization using a query parameter.
    if (to.query.organization_id) {
      const organizationId = Number(to.query.organization_id)
      if (!stores.user.user.organizations.some((organization) => organization.id === organizationId)) {
        return next('/')
      }

      await stores.organization.switch(organizationId)
      await Cache.tags('organization').put(stores.user.user.id, stores.organization.id)
      const query = to.query
      delete query.organization_id

      return next({ path: to.path, query })
    }

    if (to.meta.organizationRequired && !stores.organization.id) {
      return next('/inactive-organisation')
    }

    if (to.meta.permission && !can(to.meta.permission)) {
      return next(Error(`Unauthorized route: missing permission ${to.meta.permission}`))
    }

    if (to.meta.notRoles && to.meta.notRoles.some((value) => hasRole(value))) {
      return next(Error('Unauthorized route: role presence'))
    }

    if (to.meta.roles && !to.meta.roles.some((value) => hasRole(value))) {
      return next(Error('Unauthorized route: missing role'))
    }

    if (to.meta.service && !(to.meta.service in stores.services.$state)) {
      return next(Error(`Unauthorized route: missing service ${to.meta.service}`))
    }

    if (to.meta.visible && !to.meta.visible()) {
      return next(Error(`Unauthorized route: not visible ${to.path}`))
    }

    if (to.path.startsWith('/client-onboarding/') && !stores.organization.has_onboarding) {
      return next(Error(`Unauthorized route: ${to.path}`))
    }

    // When a user tries to access an organization that they are not in, without explicitly enabled ghost mode, switch back to an organization that they are in.
    const organizationId = Number(to.params.organizationId ?? stores.organization.id)
    if (
      organizationId &&
      to.meta.organizationRequired &&
      stores.user.authenticated &&
      !to.path.startsWith('/administration/organizations/') &&
      !stores.user.user.organizations.some((organization) => organization.id === organizationId) &&
      !(await Cache.tags('globals').get('ghostMode')) &&
      !stores.organization.account_access_consent
    ) {
      router.replace('/')
      await stores.organization.switch(stores.user.user.organizations[0]?.id)
    }

    if (triggerRouterGuards) {
      return next(to)
    }

    next()
  })

  router.afterEach((to, from) => {
    if (from.meta.guest && !to.meta.guest) {
      // When we are logging in or after a password reset, a special transition is used.
      to.meta.transition = 'v-slide-out-right-fade'
    } else {
      to.meta.transition = 'v-fade-fast'
    }

    if (to.meta.title) {
      setDocumentTitle(to.meta.title)
    }

    if (history.value.at(-2) === to.fullPath) {
      // Handle going back to previous page.
      history.value.splice(history.value.length - 1, 1)
    } else {
      history.value.push(to.fullPath)
    }

    userpilotClient.reload()
  })
}
