import { acceptHMRUpdate, defineStore } from 'pinia'

import { type CaseSourceName, type CaseType, type DataSource } from '~/types.ts'

export const useDataSources = defineStore('dataSources', {
  state: () => ({
    dataSources: [] as DataSource[],
  }),

  getters: {
    activeSources: (state) => {
      const activeSources: Record<CaseType, Set<CaseSourceName>> = { Person: new Set(), Business: new Set() }
      const caseTypes: CaseType[] = ['Person', 'Business']
      for (const caseType of caseTypes) {
        for (const dataSource of state.dataSources) {
          if (dataSource.type !== caseType) {
            continue
          }

          if (dataSource.active) {
            activeSources[caseType].add(dataSource.source)
          }
        }
      }

      return activeSources
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataSources, import.meta.hot))
}
