import Echo from 'laravel-echo'
import type { PusherChannel } from 'laravel-echo/dist/channel/index.d.ts'
import Pusher from 'pusher-js/with-encryption/index.js'

function createEcho() {
  return new Echo({
    Pusher,
    broadcaster: 'reverb',
    cluster: '',
    forceTLS: window.location.protocol === 'https:',
    key: 'app',
    wsHost: window.location.hostname,
    wsPort: window.location.port,
    encrypted: true,
    enabledTransports: ['wss', 'ws'],
    wsPath: '',
    authorizer: (channel: PusherChannel) => {
      return {
        authorize: async (socketId: string, callback: (arg0: boolean, arg1: any) => void) => {
          try {
            const { data } = await api.post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
            })

            // Make sure that broadcast->toOthers works
            window.api.headers['X-Socket-ID'] = socketId

            callback(false, data)
          } catch (error) {
            callback(true, error)
          }
        },
      }
    },
  })
}

export { createEcho }
