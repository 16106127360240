// Polyfill for Safari 14/15
import 'core-js/actual/array/at.js'
import 'core-js/actual/array/find-last-index.js'

import mapboxgl from 'mapbox-gl'

import { api, http, web } from './services/http.ts'

window.web = web
window.http = http
window.api = api

window.initialStatePromise = api.get('initial-state')

window.nextFrame = nextFrame
window.delay = delay

window.mapboxgl = mapboxgl
mapboxgl.prewarm()

window.copyObject = (obj) => JSON.parse(JSON.stringify(obj))
