import maxBy from 'lodash-es/maxBy.js'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { type Discount } from '~/types.ts'

export const useDiscounts = defineStore('discounts', () => {
  const discounts = ref<Discount[]>([])
  const loading = ref(false)
  const activeDiscounts = computed(() =>
    discounts.value.filter((discount) => {
      return dayjs(discount.starts_at).isBefore(dayjs()) && dayjs(discount.expires_at).isAfter(dayjs())
    }),
  )
  const activeDiscount = computed(() => maxBy(sortArrayByObjectKey({ array: activeDiscounts.value, key: 'discount_percentage', order: 'desc' }), 'minimum_cost'))

  async function fetch() {
    loading.value = true
    try {
      const { data } = await api.post('discounts/searches', { organization_id: [stores.organization.id] })
      discounts.value = data
    } finally {
      loading.value = false
    }
  }

  return {
    discounts,
    loading,
    activeDiscounts,
    activeDiscount,
    fetch,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDiscounts, import.meta.hot))
}
