import { acceptHMRUpdate, defineStore } from 'pinia'

import { type CaseFilters } from '~/composables/useCaseFilters.ts'
import { type AtLeast, type Batch, type CaseModel, type CaseModelDetailed, type Overwrite } from '~/types.ts'

export interface CaseAssignRequest {
  user_id?: null | number
  group_id?: null | number
  filters: AtLeast<CaseFilters, 'id'>
}

export type UpdateCaseRequest = AtLeast<Overwrite<CaseModel, { clients: number[]; passportVerificationForm?: Record<string, any>; search?: boolean }>, 'id'>

export const useCases = defineStore('cases', {
  state: () => ({
    opened: {} as Record<number, CaseModelDetailed>,
  }),

  actions: {
    async loadCase(id: number) {
      await stores.loading.update({ cases: true })

      let caseModel: CaseModelDetailed | null = null

      try {
        const { data } = await api.get<CaseModelDetailed>(`cases/${id}`)
        this.opened[data.id] = data
        caseModel = data
      } catch {
        $message.error($t('api-error'))
      }

      await stores.loading.update({ cases: false })

      return caseModel
    },

    async loadCaseFromUUID(uuid: string) {
      await stores.loading.update({ cases: true })

      let caseModel: CaseModelDetailed | null = null

      try {
        const { data } = await api.get<CaseModelDetailed>(`cases/uuid/${uuid}`)
        this.opened[data.id] = data
        caseModel = data
      } catch {
        $message.error($t('api-error'))
      }

      await stores.loading.update({ cases: false })

      return caseModel
    },

    async assign(payload: CaseAssignRequest) {
      try {
        await stores.loading.update({ cases: true })

        const { data } = await api.patch<Batch>('cases', payload)
        $bus.emit('batch.created', data)
        $bus.until('batch.finished', (id) => {
          if (id === data.id) {
            $bus.emit('cases.updateFilters', {})

            return true
          }

          return false
        })
      } catch (error) {
        console.error(error)
        $message.error($t('api-error'))
      } finally {
        await stores.loading.update({ cases: false })
      }
    },

    async update(payload: UpdateCaseRequest) {
      try {
        await stores.loading.update({ cases: true })

        const { data } = await api.patch<Omit<CaseModel, 'organization'>>(`v1/cases/${payload.id}`, payload)

        this.updateOpened(data)
      } catch (error) {
        console.error(error)
        $message.error($t('api-error'))
      } finally {
        await stores.loading.update({ cases: false })
      }
    },

    async remove(ids: number[]) {
      try {
        const { data } = await api.delete<Batch>('cases', { data: { filters: { id: ids } } })

        $bus.emit('batch.created', data)
        $bus.until('batch.finished', (id) => {
          if (id === data.id) {
            $bus.emit('cases.fetch')

            return true
          }

          return false
        })

        for (const id of ids) {
          if (stores.case.caseId === id) {
            stores.case.reset()
          }
        }

        for (const id of ids) {
          if (id in this.opened) {
            delete this.opened[id]
          }
        }
        $message.success($t('the-case-has-been-successfully-deleted', ids.length))
      } catch (error) {
        console.error(error)
        $message.error($t('api-error'))
      }
    },

    updateOpened(caseModel: AtLeast<CaseModelDetailed, 'id'>) {
      if (!(caseModel.id in this.opened)) {
        return
      }

      const sourcesBefore = new Set(this.opened[caseModel.id].sources.filter((source) => source.active).map((source) => source.data_source.source))
      this.opened[caseModel.id] = { ...this.opened[caseModel.id], ...caseModel }

      // Check if sources were removed.
      const removedSources = new Set(
        this.opened[caseModel.id].sources.filter((source) => !source.active && sourcesBefore.has(source.data_source.source)).map((source) => source.data_source.source),
      )

      if (removedSources.size) {
        this.opened[caseModel.id].hits = this.opened[caseModel.id].hits.filter((hit) => hit.resolution !== 'unresolved' || !removedSources.has(hit.source))
        if (stores.case.caseId === caseModel.id) {
          for (const removedSource of removedSources) {
            stores.case[removedSource].$reset()
            stores.case[removedSource].loadHitsFromCase(this.opened[caseModel.id])
          }
        }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCases, import.meta.hot))
}
