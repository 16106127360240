import { acceptHMRUpdate, defineStore } from 'pinia'

import { type CaseSourceName, type CaseType, type Env, type OrganizationPolicy } from '~/types.ts'

export const useInitialState = defineStore('initialState', {
  state: () => ({
    adverse: {
      keys: [] as string[],
      labels: {} as Record<string, string>,
      minimum_threshold: 0,
    },
    availableServices: {} as Record<string, string>,
    caseAuditTypes: {} as Record<string, string>,
    companyAbbreviations: [] as string[],
    defaultPolicies: {} as OrganizationPolicy,
    env: {
      app_name: (document.head.querySelector('meta[name="app-name"]') as HTMLMetaElement).content,
      app_env: '',
      browser: {
        name: '',
        version: '',
      },
      version: '',
    } as Env,
    gracePeriod: { days_until_inactive: 60, days_until_deleted: 120 },
    defaultMaximumSearches: {} as Record<string, number>,
    ignoredUpdateFields: [] as string[],
    newsCoverageMultiplier: 0,
    sessionId: 0,
    sourceFields: {} as Record<CaseSourceName, string[]>,
    sourceKeyFields: {} as Record<CaseSourceName, string[]>,
    sourceLabels: {} as Record<CaseSourceName, string>,
    sourcesPerType: {} as Record<CaseType, CaseSourceName[]>,
  }),

  actions: {
    async load() {
      const { data } = await window.initialStatePromise

      this.$state = data
      app.config.globalProperties.$env = window.$env = this.env
      if ($env.browser.name === '') {
        return
      }

      stores.policies.load({ defaults: data.defaultPolicies })
      stores.source.$state = { fields: data.sourceFields, keyFields: data.sourceKeyFields, perType: data.sourcesPerType, labels: data.sourceLabels }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInitialState, import.meta.hot))
}
