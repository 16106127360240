import { acceptHMRUpdate, defineStore } from 'pinia'

import { type MarketplacePack } from '~/types.ts'

export const useMarketplacePacks = defineStore('marketplacePacks', {
  state: () => ({
    loading: false,
    packs: {} as Record<string, MarketplacePack>,
    orderedIds: [11, 12, 13, 7, 14, 4, 3, 8, 5, 10, 9] as const,
    hiddenPackIds: [8, 9, 14],
  }),

  getters: {
    activePacks: (state) => Object.values(state.packs).filter((pack) => stores.dataSources.dataSources.some((item) => item.active && item.marketplace_pack_id === pack.id)),

    orderedPacks: (state): MarketplacePack[] => {
      if (Object.keys(state.packs).length === 0) {
        return []
      }

      return state.orderedIds.map((id) => state.packs[id]).filter((pack) => pack)
    },
  },

  actions: {
    async fetch() {
      if (this.loading || Object.keys(this.packs).length) {
        return
      }

      this.loading = true
      try {
        const { data } = await api.get<MarketplacePack[]>('marketplace-packs')
        this.packs = keyedBy('id', data)
      } finally {
        this.loading = false
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMarketplacePacks, import.meta.hot))
}
