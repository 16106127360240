import { createHTTPClient, type RequestError } from '@vartion/ui'

const csrfToken = (document.getElementsByName('csrf-token')[0] as HTMLMetaElement).content as string
const apiTokenElement = document.head.querySelector<HTMLMetaElement>('meta[name="api-token"]')
if (apiTokenElement) {
  document.head.removeChild(apiTokenElement)
}

let apiToken = apiTokenElement?.content ?? null
if (apiToken) {
  sessionStorage.setItem('api-token', apiToken)
} else {
  apiToken = sessionStorage.getItem('api-token')
}

export const web = createHTTPClient({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken,
    Authorization: apiToken ? `Bearer ${apiToken}` : undefined,
  },
})

export const http = createHTTPClient()

const api = createHTTPClient({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken,
    Authorization: apiToken ? `Bearer ${apiToken}` : undefined,
  },
})

let interceptor = -1

async function interceptExpiredSessions(error: RequestError) {
  if (interceptor > -1 && error.response?.status === 419) {
    api.interceptors.response.eject(interceptor)
    interceptor = -1

    window.location.reload()
    // Wait a bit so no error is shown while reloading
    await delay(3000)
  }

  return error
}
interceptor = api.interceptors.error.use(interceptExpiredSessions)

let currentRequestsCount = 0
api.interceptors.request.use((config) => {
  currentRequestsCount++
  window.idle = false

  // Fix for PHP bug: https://github.com/laravel/framework/issues/13457#issuecomment-455570274
  if (config.method === 'PATCH' && config.data instanceof FormData) {
    config.data.append('_method', 'PATCH')
    config.method = 'POST'
  }
  if (config.method === 'PUT' && config.data instanceof FormData) {
    config.data.append('_method', 'PUT')
    config.method = 'POST'
  }

  return config
})
api.interceptors.response.use((response) => {
  currentRequestsCount--
  if (currentRequestsCount === 0) window.idle = true

  return response
})

api.interceptors.error.use((error) => {
  currentRequestsCount--
  if (currentRequestsCount === 0) window.idle = true

  return Promise.reject(error)
})

export { api }
