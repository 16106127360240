import { acceptHMRUpdate, defineStore } from 'pinia'

import { type RiskConfig } from '~/types.ts'

export const useRiskConfig = defineStore('riskConfig', {
  state: () => ({
    config: {} as RiskConfig,
    saved: {} as RiskConfig,
    defaults: {} as RiskConfig,
  }),

  actions: {
    async load() {
      if (stores.loading.riskConfig || Object.keys(this.config).length > 0) return
      await stores.loading.update({ riskConfig: true })

      try {
        const { data } = await api.get(`risk-configs/${stores.organization.id}`)

        this.config = data
        this.saved = copyObject(data)

        const { data: defaults } = await api.get('risk-config-defaults')
        this.defaults = defaults
      } finally {
        await stores.loading.update({ riskConfig: false })
      }
    },

    async save() {
      await stores.loading.update({ riskConfig: true })

      try {
        const { data } = await api.patch(`risk-configs/${this.config.id}`, this.config)
        this.defaults = data
        this.saved = copyObject(data)
      } finally {
        await stores.loading.update({ riskConfig: false })
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRiskConfig, import.meta.hot))
}
