import { setMessages } from '@vartion/ui'
import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

import type { Locale } from '~/types.ts'
import { onImportError } from './updateChecker.ts'

// Define the locales that we use. The label is shown in the interface for the user.
export const locales: Record<Locale, string> = {
  'en-gb': 'English (UK)',
  en: 'English (US)',
  nl: 'Dutch',
  es: 'Spanish',
  ru: 'Russian',
} as const

// Create a map of locales for dayjs to use.
// Otherwise vite is not able to determine which locales should be tree shaken.
const loadDayjsLocale: Record<Locale, () => Promise<Record<string, unknown>>> = {
  en: async () => await import('dayjs/locale/en.js'),
  'en-gb': async () => await import('dayjs/locale/en-gb.js'),
  es: async () => await import('dayjs/locale/es.js'),
  nl: async () => await import('dayjs/locale/nl.js'),
  ru: async () => await import('dayjs/locale/ru.js'),
} as const

export const instance = createI18n({
  legacy: false,
  locale: '', // Locale will be filled in by `setLocale` in `window.localePromise`.
  fallbackLocale: 'en-gb',
  missingWarn: false,
  fallbackWarn: false,
})
export const i18n = instance.global

let hasLocale = false

/**
 * Set the current application language.
 *
 * @param locale The locale key of the language that should be set.
 */
export async function setLocale(locale: Locale) {
  if (hasLocale && locale === i18n.locale.value) {
    return
  }

  const alreadyLoading = stores.loading.app
  if (!alreadyLoading) {
    await stores.loading.update({ app: true })
  }

  // Set application locale
  const messages = await import(`../locales/${locale}.json`).catch(onImportError)
  i18n.setLocaleMessage(locale, messages.default)
  i18n.locale.value = locale

  document.documentElement.lang = locale

  // Set dayjs locale
  await loadDayjsLocale[locale]()
  dayjs.locale(locale)

  // Set @vartion/ui strings
  setMessages({
    Confirm: {
      cancel: i18n.t('cancel'),
      confirm: i18n.t('confirm'),
    },
    DisabledHelp: {
      invalidInput: i18n.t('invalid-input'),
      thisButtonIsDisabledBecause: i18n.t('this-button-is-disabled-because'),
    },
    VCalendar: {
      clear: i18n.t('clear'),
      confirm: i18n.t('confirm'),
    },
    VForm: {
      match: i18n.t('this-field-does-not-match'),
      gt: i18n.t('this-field-must-be-greater-than-gt'),
      gte: i18n.t('this-field-must-be-equal-to-gte-or-greater'),
      in: i18n.t('this-field-must-be-one-of-in'),
      lt: i18n.t('this-field-must-be-less-than-lt'),
      lte: i18n.t('this-field-must-be-equal-to-lte-or-less'),
      max: i18n.t('this-field-must-have-a-length-of-at-most-max'),
      maxString: i18n.t('this-field-can-not-be-longer-than-max-characters'),
      min: i18n.t('this-field-must-have-a-length-of-at-least-min'),
      minString: i18n.t('this-field-must-be-at-least-min-characters-long'),
      required: i18n.t('this-field-is-required'),
      type: i18n.t('this-field-must-be-type-type'),
    },
    VInput: {
      required: i18n.t('required').toLowerCase(),
      optional: i18n.t('optional').toLowerCase(),
    },
    VSelect: {
      noResultsFound: i18n.t('no-results-found'),
      noResultsFoundForYourInput: i18n.t('no-results-found-for-your-input'),
      addAll: i18n.t('add-all'),
      suggested: i18n.t('suggested-label'),
      showMore: i18n.t('show-more'),
      showLess: i18n.t('show-less'),
    },
    VUploadDragger: {
      dragAndDrop: i18n.t('drag-and-drop'),
      or: i18n.t('or'),
      clickToUpload: i18n.t('click-to-upload'),
    },
    VFormBuilder: {
      addAnswerOption: i18n.t('add-answer-option'),
      addFollowUpQuestion: i18n.t('add-follow-up-question'),
      addQuestion: i18n.t('add-question'),
      addSection: i18n.t('add-section'),
      addSubtitle: i18n.t('add-subtitle'),
      allowMultipleAnswers: i18n.t('allow-multiple-answers'),
      allowThirdOption: i18n.t('allow-third-option'),
      answer: i18n.t('answer'),
      answerOptions: i18n.t('answer-options'),
      confirmRemove: i18n.t('are-you-sure-you-want-to-remove-title'),
      countQuestionsRequireAttention: `${i18n.t('count-question-requires-attention')} | ${i18n.t('count-questions-require-attention')}`,
      datepicker: i18n.t('date-picker'),
      dropdown: i18n.t('drop-down'),
      enterTheQuestion: i18n.t('enter-the-question'),
      enterTheSubtitle: i18n.t('enter-the-subtitle'),
      enterTitle: i18n.t('enter-title'),
      fileUpload: i18n.t('file-upload'),
      internal: i18n.t('internal'),
      no: i18n.t('no'),
      number: i18n.t('number'),
      optional: i18n.t('optional'),
      paragraph: i18n.t('paragraph'),
      question: i18n.t('question'),
      rename: i18n.t('rename'),
      sections: i18n.t('sections'),
      shortAnswer: i18n.t('short-answer'),
      startByAddingYourFirstQuestion: i18n.t('start-by-adding-your-first-question'),
      subtitle: i18n.t('subtitle'),
      trueFalse: i18n.t('true-false'),
      url: i18n.t('url'),
      userCanEnterANumber: i18n.t('user-can-enter-a-number'),
      userCanEnterAURL: i18n.t('user-can-enter-a-url'),
      userCanPickADate: i18n.t('user-can-pick-a-date'),
      userCanTypeLongTextInATextarea: i18n.t('user-can-type-long-text-in-a-text-area'),
      userCanTypeTextInATextarea: i18n.t('user-can-type-text-in-a-text-area'),
      userCanUploadOrDragFileHere: i18n.t('user-can-upload-or-drag-file-here'),
      yes: i18n.t('yes'),
    },
  })

  if (!alreadyLoading) {
    await stores.loading.update({ app: false })
  }

  hasLocale = true

  return nextTick()
}

type TranslateParams = Parameters<typeof i18n.t>
export function tk(key: TranslateParams[0], plural?: TranslateParams[1], options?: TranslateParams[2]) {
  if (!plural) {
    return i18n.t(kebab(String(key)))
  }

  if (!options) {
    return i18n.t(kebab(String(key)), plural)
  }

  return i18n.t(kebab(String(key)), plural, options)
}
