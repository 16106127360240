import type { VCalendarQuickOption } from '@vartion/ui'

import { i18n } from '~/services/i18n.ts'

function createDateOptions(): VCalendarQuickOption[] {
  return [
    { label: i18n.t('previous-year'), value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')] },
    { label: i18n.t('last-count-days', 365), value: [dayjs().subtract(365, 'day'), dayjs()] },
    { label: i18n.t('this-year'), value: [dayjs().startOf('year'), dayjs().endOf('year')] },
    { label: i18n.t('last-count-days', 31), value: [dayjs().subtract(31, 'day'), dayjs()] },
    { label: i18n.t('this-month'), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
    { label: i18n.t('last-count-days', 7), value: [dayjs().subtract(7, 'day'), dayjs()] },
    { label: i18n.t('this-week'), value: [dayjs().startOf('week'), dayjs().endOf('week')] },
    { label: i18n.t('today'), value: [dayjs(), dayjs()] },
  ]
}

const dateOptions = ref(createDateOptions())

watch(
  () => i18n.locale.value,
  () => (dateOptions.value = createDateOptions()),
)

export const useDateOptions = function () {
  return dateOptions
}
