<template>
  <RouterView v-if="loaded" v-slot="{ Component }">
    <Transition mode="out-in" :name="route.meta.transition || 'v-fade-fast'">
      <Component :is="Component" />
    </Transition>
  </RouterView>
</template>

<script setup lang="ts">
import { Cache } from '~/services/cache/Cache.ts'
import router, { createRouterGuards, route } from './router.ts'

const loaded = ref(false)

async function onCreated() {
  if (isOneTimeCase()) {
    await router.replace('/one-time-case')
  }

  try {
    // Try to load the user if it is already authenticated.
    await Promise.all([stores.initialState.load(), window.localePromise])
    await stores.user.load()
    await stores.organization.load()
  } catch {
    // If we're not authenticated, redirect to the login page.
    if (!route.value.meta.guest) {
      if (route.value.fullPath !== '/') {
        await router.replace({ path: '/login', query: { next: route.value.fullPath } })
      } else {
        await router.replace('/login')
      }
    }
  }

  // Azure sign in redirects back here. We check if we had an intended route to go to.
  // Redirect to that route now that we are authenticated.
  const next = await Cache.tags('router').get('next')
  if (next) {
    await router.replace({ path: next })
    Cache.tags('router').flush()
  }

  // After the user is loaded or redirected to the login page, we can start the permission and organizationId watchers.
  watch(
    () => route.value.meta.permission,
    (permission) => {
      // If the route has 'administrations.view' permission attached, it means it is a spectrum route.
      const inAdministration = permission === 'administrations.view'
      app.config.globalProperties.$inAdministration = inAdministration
    },
    { immediate: true },
  )

  // If the route `organizationId` param changes, try to switch to that organization.
  watch(
    () => route.value.params.organizationId,
    async (organizationId) => {
      if (stores.user.authenticated && organizationId) {
        const id = Number(organizationId)
        await Cache.tags('organization').put(stores.user.user.id, id)
        stores.organization.switch(id)
      }
    },
    { immediate: true },
  )

  // We can now create the router guards.
  // These prevent us from going to pages we are not supposed to access.
  createRouterGuards()

  // Trigger the router guards to make sure we can access the page.
  await router.replace({ ...router.currentRoute.value, query: { ...router.currentRoute.value.query, triggerRouterGuards: Date.now() } })

  // Finally show the page.
  loaded.value = true
}

// Wait for vue router to be ready, otherwise route.value will not be correct in onCreated.
router.isReady().then(() => onCreated())
</script>
