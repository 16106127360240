import { type User } from '~/types.ts'

function compareValues(a, b) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  }

  if ((a === undefined || a === null) && (b === undefined || b === null)) {
    return 0
  }

  if ((a === undefined || a === null) && b !== undefined && b !== null) {
    return 1
  }

  if ((b === undefined || b === null) && a !== undefined && a !== null) {
    return -1
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length - b.length
  }

  if (typeof a === 'object' && typeof b === 'object') {
    return Object.keys(a).length - Object.keys(b).length
  }

  return a - b
}

/**
 * @description Sort an object by their keys
 * @param object The object that should be sorted
 * @param transform a transformation function to apply before comparing keys
 */
export function sortObjectByKeys<T extends Record<string, unknown>>(object: T, transform?: (value: any) => any): T {
  const sorted = {}

  for (const key of Object.keys(object).sort((a, b) => (transform ? compareValues(transform(a), transform(b)) : compareValues(a, b)))) {
    sorted[key] = object[key]
  }

  return sorted
}

/**
 * @description Sort an object by their values
 * @param object The object that should be sorted
 * @param sortNumbers Indicate of numbers should be sorted. Will number keys to strings
 */
export function sortObjectByValues<T extends Record<string, unknown>>(object: T, sortNumbers = false): T {
  const sorted = {}

  for (const [key, value] of Object.entries(object).sort((a, b) => a[1] - b[1])) {
    if (sortNumbers && /^\d+$/.test(key)) {
      sorted[`${key} `] = value
    } else {
      sorted[key] = value
    }
  }

  return sorted
}

export function sortUsers(users: Record<number, User> | User[]) {
  return Object.values(users).sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`))
}
