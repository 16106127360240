import { type ExportedBlueprint, type Question, type VTagType } from '@vartion/ui'

export type AppName = 'screening' | 'spectrum' | 'onboarding'

export type { MentionableModelValue as MentionableComment } from '@vartion/ui'

export interface Batch {
  id: string
  finishedAt: string | null
  name: string
  progress: number
  totalJobs: number
  failedJobs: number
  processedJobs: number
}
export interface IntelligentCheckJob {
  type: string
  caseId: number
  filteredSourceIds: number[]
}

export interface BillingSettings {
  address: string | null
  email_recipient_roles: string[]
  email_recipient_addresses: string[]
  city: string | null
  country: string
  created_at: string
  credits_notification: boolean
  id: number
  order_day_of_month: number
  organization_id: number
  postal_code: string | null
  tax_id: string | null
  threshold: number
  threshold_notification: boolean
  updated_at: string
}

export type Confidence = 'Relative' | 'Exact'

export interface BusinessForm {
  name: string
  aliases: null | string[]
  address: null | string
  company_number: null | string
  jurisdiction: null | string
  additional_terms: null | string[]
}

export interface CaseAuditBucket {
  key: string
  count: number
  year: number
  month: number
  day: number
  description: string
  by_app_employee: boolean
  created_at: string
  type: string
  user_id: number
  user_name: string
}

export interface BaseCaseAudit {
  id: number
  case_id: number
  user_id: number
  case_hit_id: null | number
  user_name: string
  description: string
  type: null | string
  data: null | Record<never, never>
  by_app_employee: boolean
  ip_address: string
  created_at: string
  updated_at: string
}

export interface AssignedCaseAudit extends BaseCaseAudit {
  type: 'assigned'
  data: null | {
    assignee_type: 'user' | 'group'
    assignee_id: number
  }
}

export interface CommentAddedCaseAudit extends BaseCaseAudit {
  type: 'comment added'
  data: null
}

export interface ConfidenceChangeCaseAudit extends BaseCaseAudit {
  type: 'confidence change'
  data: { confidence: Confidence }
}

export interface CreatedCaseAudit extends BaseCaseAudit {
  type: 'created'
  data: null
}

export interface CreatedCaseAudit extends BaseCaseAudit {
  type: 'created'
  data: null
}

export interface DeletedCaseAudit extends BaseCaseAudit {
  type: 'deleted'
  data: null
}

export interface HitUpdatedCaseAudit extends BaseCaseAudit {
  type: 'hit updated'
  data: null
}

export interface HyperlinkSearchCaseAudit extends BaseCaseAudit {
  type: 'hyperlink search'
  data: { name: string }
}

export interface PassportVerificationStatusChangeCaseAudit extends BaseCaseAudit {
  type: 'passport verification status change'
  data: { status: string }
}

export interface ServiceAddedCaseAudit extends BaseCaseAudit {
  type: 'service added'
  data: { service: string }
}

export interface StatusChangeCaseAudit extends BaseCaseAudit {
  type: 'status change'
  data: { status: string }
}

export interface InputChangeCaseAudit extends BaseCaseAudit {
  type: 'input change'
  data: null
}

export interface HitResolvedCaseAudit extends BaseCaseAudit {
  type: 'hit resolved'
  data: null | {
    included: boolean
  }
}

export interface MonitoringFrequenciesChangedCaseAudit extends BaseCaseAudit {
  type: 'monitoring frequencies changed'
  data: {
    old: Partial<Record<MonitoringFrequencyKey, MonitoringFrequency>>
    new: Partial<Record<MonitoringFrequencyKey, MonitoringFrequency>>
  }
}

export type CaseAudit =
  | AssignedCaseAudit
  | CommentAddedCaseAudit
  | ConfidenceChangeCaseAudit
  | CreatedCaseAudit
  | DeletedCaseAudit
  | HitResolvedCaseAudit
  | HitUpdatedCaseAudit
  | HyperlinkSearchCaseAudit
  | InputChangeCaseAudit
  | PassportVerificationStatusChangeCaseAudit
  | ServiceAddedCaseAudit
  | StatusChangeCaseAudit
  | MonitoringFrequenciesChangedCaseAudit

export type CaseGender = '' | 'Male' | 'Female'

export interface PersonForm {
  name: string
  aliases: null | string[]
  country_of_birth: null | string
  country_of_residence: null | string
  date_of_birth: null | string
  gender: null | CaseGender
  nationalities: null | string[]
  additional_terms: null | string[]
}

export interface CaseForms {
  Business: BusinessForm
  Person: PersonForm
}

export type CaseForm = BusinessForm | PersonForm

export const CASE_STATUSES = ['Preview', 'In review', 'Monitored', 'Archived'] as const

export type CaseStatus = (typeof CASE_STATUSES)[number]

export const CASE_STATUS_COLORS = { Preview: 'primary', 'In review': 'blue-5', Monitored: 'info', Archived: 'info', Active: 'primary' } as Record<string, VTagType>

export type CaseType = 'Business' | 'Person'

export type MonitoringFrequency = 0 | 1 | 7 | 28 | 365

export interface CaseModel<T extends CaseType = CaseType> {
  id: number
  uuid: string
  name: string
  type: T
  confidence: Confidence
  status: CaseStatus
  origin: 'person-check' | 'business-check' | 'country-check' | 'hyperlink'
  user_id: number | null
  user: User
  group_id: number | null
  group: Group
  organization_id: number
  organization: Organization
  risk: number | null
  excluded_terms: string[]
  unresolved_risk: number | null
  created_at: string
  updated_at: string
  searched_at: string | null
  deleted_at: string | null
  clients: Client[]
  hits_unresolved_count: number
  resolve_progress: number
  services: OrganizationServiceName[]
  sources: CaseSource[]
  case_users: CaseUser[]
  case_groups: CaseGroup[]
  description: null | string
  monitoring_frequency_sanctions: MonitoringFrequency
  monitoring_frequency_peps: MonitoringFrequency
  monitoring_frequency_news: MonitoringFrequency
  monitoring_frequency_enforcements: MonitoringFrequency
  monitoring_frequency_other: MonitoringFrequency
  aliases: null | string[]
  additional_terms: null | string[]
  country_of_birth: null | string
  country_of_residence: null | string
  date_of_birth: null | string
  gender: null | CaseGender
  nationalities: null | string[]
  address: null | string
  company_number: null | string
  jurisdiction: null | string
}

export interface CaseModelWithHitsUpdatedCount extends CaseModel {
  hits_updated_count: number
}

export interface CaseModelDetailed<T extends CaseType = CaseType> extends CaseModel<T> {
  hits: CaseHit[]
  hit_comments: HitComment[]
  report_schedule: ReportSchedule | null
  passport_verification: PassportVerification
  excluded_terms: string[]
  hubspot_portal_object_id: null | string
  salesforce_object_id: null | string
}

export type FormattedCaseModel = CaseModel & {
  assignee: string
  group: string
  riskLabel: string | null
}

export interface CaseGroup {
  id: number
  case_id: number
  group_id: number
  created_at: string
  updated_at: string
}

export interface CaseUser {
  id: number
  case_id: number
  user_id: number
  created_at: string
  updated_at: string
}

export interface CaseSource {
  active: boolean
  id: number
  case_id: number
  data_source_id: number
  data_source: CaseSourceDataSource
  searched_at: string | null
  succeeded: boolean
}

export const CASE_SOURCE_NAMES = ['enforcements', 'news', 'other', 'peps', 'sanctions'] as const

export type CaseSourceName = (typeof CASE_SOURCE_NAMES)[number]

export interface CaseSourceDataSource {
  id: number
  source: CaseSourceName
  vendor: string
  active: boolean
  deactivated_at: null | string
}

export interface ClientCollaborator {
  id: number
  client_id: number
  user_id: number
  created_at: string
  updated_at: string
}

export interface ConfigurationFlow {
  id: number
  organization_id: number
  progress: number
  completed: boolean
  active: boolean
  created_at: string
  updated_at: string
}

export interface CountryInformation {
  governance_indicators: Record<string, { year: number; value: number }>
  fatf: string
  cpi: { year: number; value: number }
  fitch_rating: { date: string; rating: string; outlook: string }
  gdp: { year: number; value: number }
  id: string
  leaders: { hits: Record<string, { name: string }> }
  name: string
  population: { year: number; value: number }
  sanctions: number
}

export interface Discount {
  id: number
  organization_id: number
  discount_percentage: number
  minimum_cost: number
  starts_at: string
  expires_at: string
  deleted_at: null | string
  created_at: string
  updated_at: string
}

export interface DiscountScheme {
  key: string
  active: boolean
  default: boolean
  starts_at: string
  expires_at: string
  discounts: Discount[]
}

export interface ApprovalTemplate {
  id: number
  organization_id: number
  name: string
  approval_steps: ApprovalStep[]
}

export interface ApprovalStep {
  id: number
  approvable_id: number
  approvable_type: string
  approved: boolean
  user_id: number
  created_at: string
  updated_at: string
  organization_functions: OrganizationFunction[]
  notifiables: User[]

  organization_function_ids: number[]
  active?: boolean
  canApprove?: boolean
}

export interface OnboardingForm {
  id: number
  external_name: string
  status: 'in progress' | 'submitted by client' | 'completed'
  organization_id: number
  client_id: number
  client?: Client
  assignee_user_id: number | null
  external_questions_total: number
  external_questions_open: number
  blueprint: ExportedBlueprint
  questions: OnboardingFormQuestion[]
  organization_theme?: OrganizationTheme
  report_path?: string
  created_at: string
  updated_at: string
}

export interface OnboardingFormInternal extends OnboardingForm {
  name: string
  questions_total: number
  questions_open: number
}

export interface OnboardingFormQuestion {
  id: number
  onboarding_form_id: number
  blueprint_question_id: number
  data: Question
  filled_in: boolean
  created_at: string
  updated_at: string
  comments: OnboardingFormQuestionComment[]
}

export interface OnboardingFormQuestionComment {
  id: number
  onboarding_form_id: number
  onboarding_form_question_id: number
  user_id: number
  user_name: string
  comment: string
  created_at: string
  updated_at: string
}

export interface OrganizationTheme {
  id: number
  organization_id: number
  company_name: string
  company_logo?: string | File
  font_family: null | string
  font_color: null | string
  background_color: null | string
  color_primary: null | string
  font_color_primary_button: null | string
  color_secondary: null | string
  font_color_secondary_button: null | string
  privacy_url: null | string
}

export interface OnboardingFormTemplate {
  id: number
  name: string
  external_name: string
  blueprint: ExportedBlueprint
  organization_id: number
  created_at: string
  updated_at: string
}

export interface OnboardingEntity {
  id: number
  name: string
  country_of_birth: null | string
  country_of_residence: null | string
  date_of_birth: null | string
  nationalities: null | string[]
  organization_id: number
  client_id: number
  case_id: number
  case: CaseModel
  created_at: string
  updated_at: string
  clients: Client[]
  resolve_progress: number
  onboarding_entity_verification: null | OnboardingEntityVerification
  email: null | string
}

export interface OnboardingEntityVerification {
  id: number
  onboarding_entity_id: number
  session_url: string
  status: string
  created_at: string
  updated_at: string
}

export interface GeoIP {
  city: string
  continent: string
  country: string
  currency: string
  default: boolean
  ip: string
  iso_code: string
  lat: number
  lon: number
  postal_code: string
  state: string
  state_name: string
  timezone: string
}

export interface NERType {
  count: number
  coverage: number
  value: string
  GENDER?: string
  occurrence?: string
}

export type RiskClassification = 'very low' | 'low' | 'moderate' | 'high' | 'very high'

export interface HitComment {
  id: number
  case_id: number
  hit_id: number
  user_id: number
  user_name: string
  source: string
  comment: string
  created_at: string
  updated_at: string
}

export interface HitDataPosition {
  position: string
  url?: string
  listed_at?: string | null
  listed_until?: string | null
  inactive?: boolean
  status?: string | null
}

export interface Characteristic {
  type?: string
  value?: string
}

export interface Officer {
  type: string | { value: string }
  name: string
  position: string
  listed_at: string
  listed_until: string
}

export interface HitDataRisk {
  score: number
  probability: string
  minSetProbability: RiskClassification
  impact: RiskClassification
  classification: RiskClassification
  components: Record<
    string,
    {
      score: number
      description: string
      classification: RiskClassification | 'disabled'
    }
  >
}

export type HitHighlight = Record<string, string[] | Record<string, string[]>>

export interface HitData {
  [key: string]: unknown
  aliases: {
    name: string
    type: string
  }[]
  adverse?: Record<string, number>
  characteristics?: Characteristic[]
  // input field -> input value -> matched field
  explanation: Record<
    string,
    Record<
      string,
      Record<
        string,
        {
          match: string
          matchDetails?: string[]
        }
      >
    >
  >
  gender?: CaseGender | { value: CaseGender } | null
  highlight?: HitHighlight
  home_company?: {
    company_number?: string
    jurisdiction_code?: string
    name: string
  }
  id: string | number
  industries?: null | string[]
  industry_types: {
    code: string
    description: string
    type: string
    code_scheme_id: string
  }[]
  jurisdiction_code?: string
  language?: string
  locations?: NERType[]
  name: string
  nationalities?: Partial<{ country_code: string; country: string }>[] | null
  officers?: Officer[]
  organizations?: NERType[]
  persons?: NERType[]
  positions?: HitDataPosition[]
  publish_date?: null | string
  score: number
  similar?: string[]
  source: string | string[]
  risk?: HitDataRisk
  summary?: string
  type?: {
    value: string
    predicted?: boolean
  }
  checked_at?: string
}

export interface SearchHit {
  data_source_id: number
  source: CaseSourceName
  data: HitData
}

export type HitResolution = 'unresolved' | 'positive' | 'negative'

export interface CaseHit extends SearchHit {
  id: number
  case_id: number
  implicit_feedback: number | null
  resolution: HitResolution
  update: null | HitData
  update_changes: null | {
    updated: Record<keyof HitData, { new: unknown; old: unknown }>
    added: Record<keyof HitData, { new: unknown }>
    removed: Record<keyof HitData, { old: unknown }>
  }
  update_risk_delta: null | number
  removed_from_source: boolean
  comments: HitComment[]
  user_risk_score: number | null
  created_at: string
  updated_at: string
  resolved_at: string | null
}

export interface HitTransformation<T> {
  hasChanges: boolean
  data: HitData & {
    vendor: string
    case_hit: T
  }
}

export type HitUpdate = HitData & {
  vendor: string
  case_hit: CaseHitTransformed
}

export type CaseHitTransformed = CaseHit &
  HitTransformation<CaseHitTransformed> & {
    update: HitUpdate | null
  }

export type SearchHitTransformed = SearchHit & HitTransformation<SearchHitTransformed>

export interface Client {
  id: number
  name: string
  status: 'Archived' | 'Active'
  onboarding_status: null | 'in progress' | 'ready for approval' | 'waiting for approval' | 'completed'
  description: string
  assignee_user_id: number | null
  organization_id: number
  external_users: User[]
  collaborators: User[]
  created_at: string
  updated_at: string
}

export interface ClientDetailed extends Client {
  cases_count: number
  total_count: number
  unresolved_count: number
  resolve_progress: number
  risk: number
}

export type ClientAuditType = ClientAudit['type']

export interface ClientAuditBucket {
  key?: string
  count: number
  data: Record<string, any> | null
  year: number
  month: number
  day: number
  description?: string
  by_app_employee: boolean
  created_at: string
  type: ClientAuditType
  user_id: number
  user_name: string
}

export interface BaseClientAudit {
  id: number
  client_id: number
  user_id: number
  user_name: string
  type: null | string
  data: null | Record<never, never>
  by_app_employee: boolean
  ip_address: string
  created_at: string
  updated_at: string
}

export interface ApprovalFlowStartedClientAudit extends BaseClientAudit {
  type: 'approval flow started'
  data: null
}

export interface ApprovalStepApprovedClientAudit extends BaseClientAudit {
  type: 'approval step approved'
  data: null
}

export interface ApprovalStepRejectedClientAudit extends BaseClientAudit {
  type: 'approval step rejected'
  data: null
}

export interface CreatedClientAudit extends BaseClientAudit {
  type: 'created'
  data: null
}

export interface FormCompletedClientAudit extends BaseClientAudit {
  type: 'form completed'
  data: { onboarding_form_id: number }
}

export interface FormSubmittedClientAudit extends BaseClientAudit {
  type: 'form submitted'
  data: { onboarding_form_id: number }
}

export interface FormCreatedClientAudit extends BaseClientAudit {
  type: 'form created'
  data: { onboarding_form_id: number }
}

export interface OnboardingStartedClientAudit extends BaseClientAudit {
  type: 'onboarding started'
  data: null
}

export type ClientAudit =
  | ApprovalFlowStartedClientAudit
  | ApprovalStepApprovedClientAudit
  | ApprovalStepRejectedClientAudit
  | CreatedClientAudit
  | FormCompletedClientAudit
  | FormCreatedClientAudit
  | FormSubmittedClientAudit
  | OnboardingStartedClientAudit

export interface Credits {
  created_at: string
  deleted_at: string | null
  expires_at: string | null
  id: number
  organization_id: number
  remaining_amount: number
  total_amount: number
  type: CreditsType
  updated_at: string
  warning_mails: number
}

export interface DataSource extends CaseSourceDataSource {
  created_at: null | string
  marketplace_pack: MarketplacePack
  marketplace_pack_id: number
  organization_id: number
  type: string
  updated_at: null | string
}

export type Locale = 'en' | 'en-gb' | 'es' | 'nl' | 'ru'

export type MarketPlacePackCategory = 'Sanctions' | 'Enforcements' | 'Politically Exposed Persons' | 'Media' | 'Business' | 'Other'

export interface MarketplacePack {
  categories: MarketPlacePackCategory[]
  created_at: string
  delivery: string
  description: string
  id: number
  name: string
  price_id: number
  updated_at: string
  vendor: string
}

export interface Meta {
  current_page: number
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export interface Monitor {
  id: number
  case_id: number
  new_hits_count: number
  should_report: boolean
  created_at: string
  updated_at: string
}

export interface MonitorReport {
  id: number
  user_id: number
  monitors: Monitor[]
  created_at: string
  updated_at: string
}

export interface PassportVerification {
  id: number
  case_id: number
  form: PassportVerificationForm
  mrz: string
  status: string
}

export interface PassportVerificationForm {
  country: string
  dateOfBirth: string
  dateOfExpiry: string
  documentNumber: string
  gender: string
  givenNames: string
  lastName: string
  nationality: string
  optionalData: string
}

export interface Permission {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export interface RiskConfig {
  id: number
  organization_id: number
  threshold: number
  active_country_profile_id: number
  country_profiles: RiskConfigCountryProfile[]
  created_at: string
  updated_at: string
}

export interface RiskConfigCountryProfile {
  id: number
  name: string
  nullWeight: number
  countries: Record<string, number | null>
}

export type RiskSourceConfigWeight = Record<string, number>
export type RiskSourceConfigWeights = Record<string, RiskSourceConfigWeight>

export interface RiskSourceConfig {
  id: number
  organization_id: number
  source: string
  impact: number
  minimum_probability: number
  fields: string[]
  weights: RiskSourceConfigWeights
  created_at: string
  updated_at: string
}

export interface Role {
  id: number
  name: string
  created_at: string
  updated_at: string
  permissions: Permission[]
}

export interface SearchResponse<T = any> {
  data: T[]
  meta: Meta
}

export interface User {
  id: number
  email: string
  first_name: string
  last_name: string
  name: string
  created_at: string
  updated_at: string
  groups: Group[]
  roles: Role[]
  pivot: UserPivot
}

export interface UserInvitation {
  id: number
  uuid: string
  user: User
  user_id: number
  organization_id: number
  expires_at: string
  created_at: string
  updated_at: string
  role_names: string[]
  group_ids: number[]
}

export interface SignupToken {
  id: number
  uuid: string
  organization_id: number
  created_at: string
  updated_at: string
}

export interface OrganizationInvitation {
  id: number
  user_first_name: string
  user_last_name: string
  user_email: string
  organization_name: string
  organization_industry: string
  organization_trial_ends_at: string | null
  organization_trial_convert_to_customer: boolean
  marketplace_pack_ids: number[]
  signup_token_id: number
  signup_token: SignupToken
  expires_at: string
  used_at: string | null
  created_at: string
  updated_at: string
  management_hubspot_object_id: number | null
  organization_startup_programme: boolean
  organization_type: OrganizationType
  organization_address: string
  organization_country: string
  organization_city: string
  organization_postal_code: string
  credits_total_amount: number | null
  credits_type: CreditsType
  credits_expires_at: string | null
  discounts: { minimum_cost: number; discount_percentage: number; starts_at: string; expires_at: string }[]
}

export interface OrganizationFunction {
  id: number
  organization_id: number
  name: string
}

export interface UserPivot {
  user_id: number
  organization_id: number
  organization_function_id: number | null
  active: boolean
  deleted_at: null | string
}

export interface UserDetailed extends Omit<User, 'pivot'> {
  roles: Role[]
  organizations: (OrganizationWithBillingSettingsAndPolicies & { pivot: UserPivot })[]
}

export interface Group {
  id: number
  name: string
}

export interface Price {
  id: number
  service: string
  price: number
  unit: string
  created_at: string
  updated_at: string
}

export const ORGANIZATION_INDUSTRIES = [
  'Aerospace & Defence',
  'Agriculture & Food',
  'Asset & Wealth Management',
  'Automotive & Assembly',
  'Banking',
  'Chemicals',
  'Education',
  'Electronics & Semiconductor',
  'Energy, Utilities & Resources',
  'Engineering and Construction',
  'Entertainment & Media',
  'Financial Services',
  'Health Care',
  'Industrial Manufacturing',
  'Insurance',
  'Investment Management',
  'Law & Legal Services',
  'Maritime',
  'Marketing',
  'Mining & Metals',
  'Pension Sector',
  'Pharma & Life Sciences',
  'Real Estate',
  'Retail & Customer',
  'Software',
  'Technology',
  'Trade',
  'Telecommunication',
  'Transport & Logistics',
  'Trust Management',
  'Other',
] as const

export type OrganizationType = 'commercial' | 'developer' | 'trial' | 'free' | 'contract'

export type CreditsType = null | 'promotional' | 'bundle' | 'service'

export interface Organization {
  id: number
  name: string
  address: null | string
  country: null | string
  industry: null | string
  city: null | string
  postal_code: null | string
  created_at: string
  updated_at: string
  invalid_payment_method_at: null | string
  trial_convert_to_customer: boolean
  trial_ends_at: string | null
  type: OrganizationType
  pending_type_change: OrganizationType | null
  parent_organization_id: number | null
  origin: null | string
  deleted_at: null | string
  microsoft_marketplace_subscription_id: null | string
  current_order: Order
  has_screening: boolean
  has_onboarding: boolean
  last_activity_at: string | null
  maximum_searches: number | null
  account_access_consent: boolean
  has_veriff?: boolean | null
}

export interface OrganizationWithBillingSettingsAndPolicies extends Organization {
  billing_settings: BillingSettings | null
  policy: OrganizationPolicy | null
}

/**
 * Credits are not returned by default, and must be specified in the `with` of the request.
 */
export interface OrganizationWithBillingSettingsAndCredits extends Organization {
  billing_settings: BillingSettings | null
  credits: Credits[]
  incomplete_configuration_flows_exists: boolean
}

export interface ManagementHubspotObject {
  pascal_organization: string
  hs_object_id: string
  createdate: string
  hs_lastmodifieddate: string
}

interface BaseSpectrumLog {
  id: number
  created_at: string
  data: Record<never, never>
  email: string
  updated_at: string
  user_id: number
  action: string
}

interface SpectrumLogUnknown extends BaseSpectrumLog {
  action: 'unknown'
}

interface SpectrumLogRoleAssign extends BaseSpectrumLog {
  action: 'role:assign'
  data: { model_id: number; role_id: number }
}

interface SpectrumLogRoleRemove extends BaseSpectrumLog {
  action: 'role:remove'
  data: { model_id: number; role_id: number }
}

export type SpectrumLog = SpectrumLogUnknown | SpectrumLogRoleAssign | SpectrumLogRoleRemove

interface BaseOrganizationAudit {
  id: number
  organization_id: number
  user_id: number[]
  created_at: string
  updated_at: string
  description: string
  type: string
  data: null | Record<never, never>
  by_app_employee: boolean
  ip_address: string
  user_name: string
}

export interface GroupUsersChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'group users changed'
  data: {
    group: string
    emails: string[]
  }
}

export interface PoliciesChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'policies changed'
  data: null | { old: Partial<OrganizationPolicy>; new: Partial<OrganizationPolicy> }
}

export interface ServiceDisabledOrganizationAudit extends BaseOrganizationAudit {
  type: 'service disabled'
  data: { service: string }
}

export interface ServiceEnabledOrganizationAudit extends BaseOrganizationAudit {
  type: 'service enabled'
  data: { service: string }
}

export interface GroupCreatedOrganizationAudit extends BaseOrganizationAudit {
  type: 'group created'
  data: { name: string }
}

export interface GroupDeletedOrganizationAudit extends BaseOrganizationAudit {
  type: 'group deleted'
  data: { name: string }
}

export interface UserCreatedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user created'
  data: { email: string }
}

export interface UserDeletedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user deleted'
  data: { email: string; cases_deleted?: boolean; nr_of_cases_deleted?: number; new_case_assignee_email?: string; new_case_assignee_group_name?: string }
}

export interface UserDeactivatedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user deactivated'
  data: { email: string; cases_deleted?: boolean; nr_of_cases_deleted?: number; new_case_assignee_email?: string; new_case_assignee_group_name?: string }
}

export interface UserEmailChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user email changed'
  data: { old_email: string; new_email: string }
}

export interface UserFirstNameChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user first name changed'
  data: { first_name: string }
}

export interface UserLastNameChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user last name changed'
  data: { last_name: string }
}

export interface UserGroupsChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user groups changed'
  data: { email: string; groups: string[] }
}

export interface UserRestoredOrganizationAudit extends BaseOrganizationAudit {
  type: 'user restored'
  data: { email: string }
}

export interface UserRolesChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'user roles changed'
  data: { email: string; roles: string[] }
}
export interface MonitoringFrequencyChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'monitoring frequency changed'
  data: { monitoring_frequency: MonitoringFrequency }
}

export interface PacksActivatedOrganizationAudit extends BaseOrganizationAudit {
  type: 'packs activated'
  data: { ids: number[] }
}
export interface PacksDeactivatedOrganizationAudit extends BaseOrganizationAudit {
  type: 'packs deactivated'
  data: { ids: number[] }
}

export interface DetailsChangedOrganizationAudit extends BaseOrganizationAudit {
  type: 'details changed'
  data: { name?: string; address?: string; country?: string; industry?: string; city?: string; postal_code?: string }
}

export type OrganizationAudit =
  | GroupUsersChangedOrganizationAudit
  | PoliciesChangedOrganizationAudit
  | ServiceDisabledOrganizationAudit
  | ServiceEnabledOrganizationAudit
  | UserCreatedOrganizationAudit
  | UserDeletedOrganizationAudit
  | UserDeactivatedOrganizationAudit
  | GroupCreatedOrganizationAudit
  | GroupDeletedOrganizationAudit
  | UserEmailChangedOrganizationAudit
  | UserFirstNameChangedOrganizationAudit
  | UserLastNameChangedOrganizationAudit
  | UserGroupsChangedOrganizationAudit
  | UserRestoredOrganizationAudit
  | UserRolesChangedOrganizationAudit
  | MonitoringFrequencyChangedOrganizationAudit
  | PacksActivatedOrganizationAudit
  | PacksDeactivatedOrganizationAudit
  | DetailsChangedOrganizationAudit

export interface OrganizationIntegration {
  id: number
  azure_tenant_id: string
  azure_require_user_request: boolean
  azure_user_request_via: string[]
  hubspot_portal_id: null | number
  hubspot_user_id: null | number
  hubspot_refresh_token: null | string
  docusign_account_id: null | string
  docusign_base_uri: null | string
  docusign_refresh_token: null | string
  has_veriff_integration: boolean
  webhook_url: null | string
  webhook_event_case_created: boolean
  webhook_event_case_searched: boolean
  webhook_event_case_hits_found: boolean
  webhook_event_case_resolved: boolean
}

export type NewsAdverseAndNegativeEventsFilter = null | 'adverse' | 'negative_events'

export interface OrganizationPolicy {
  id: number
  organization_id: number
  default_case_sources: string[]
  confidence_threshold_enforcements: number
  confidence_threshold_news: number
  confidence_threshold_other: number
  confidence_threshold_peps: number
  confidence_threshold_sanctions: number
  news_adverse_and_negative_events_filter: NewsAdverseAndNegativeEventsFilter
  news_age_years_filter: null | number
  preview_default_case_sources: string[]
  preview_confidence_threshold_enforcements: number
  preview_confidence_threshold_news: number
  preview_confidence_threshold_other: number
  preview_confidence_threshold_peps: number
  preview_confidence_threshold_sanctions: number
  preview_news_adverse_and_negative_events_filter: NewsAdverseAndNegativeEventsFilter
  preview_news_age_years_filter: null | number
  monitoring_frequency_sanctions: MonitoringFrequency
  monitoring_frequency_peps: MonitoringFrequency
  monitoring_frequency_news: MonitoringFrequency
  monitoring_frequency_enforcements: MonitoringFrequency
  monitoring_frequency_other: MonitoringFrequency
  edit_cases: string
  hit_update_fields: string[]
  read_cases: string
  require_hit_comments: boolean
  default_assignee_type: 'self' | 'user' | 'group'
  default_assignee_user_id: number | null
  default_assignee_group_id: number | null
  require_one_time_password: boolean
  created_at: string
  updated_at: string
  external_users_language: Locale
}

export type ConfidenceThresholdKey =
  | 'confidence_threshold_enforcements'
  | 'confidence_threshold_news'
  | 'confidence_threshold_sanctions'
  | 'confidence_threshold_peps'
  | 'confidence_threshold_other'

export type MonitoringFrequencyKey =
  | 'monitoring_frequency_sanctions'
  | 'monitoring_frequency_peps'
  | 'monitoring_frequency_enforcements'
  | 'monitoring_frequency_news'
  | 'monitoring_frequency_other'

export type OrganizationServiceName = 'privateWatchlist' | 'passportVerification' | 'riskClassification' | 'mediaDeduplication' | 'implicitFeedback'

export interface OrganizationService {
  id: number
  organization_id: number
  name: OrganizationServiceName
  created_at: string
  updated_at: string
}

export interface Env {
  app_env: string
  app_name: string
  maximum_free_searches: number
  maximum_trial_searches: number
  browser: {
    name: string
    version: string
  }
  cdn_url: string
  version: string
  mapbox: {
    token: string | null
  }
  sentry: {
    dsn: string | null
  }
  userpilot: {
    token: string | null
    resource_center_id: string
    legacy_date: string
  }
}

export interface Order {
  id: number
  organization_id: number
  invoice: Invoice
  invoice_id: number
  organization: Organization
  payments: OrderPayment[]
  latest_payment: OrderPayment | null
  order_usage: OrderUsage
  send_at: string | null
  threshold_mails_send: number
  starts_at: string
  ends_at: string
  created_at: string
  updated_at: string
  cases_created_count: number
  searches_count: number
  status: OrderStatus | null
}

export interface OrderUsage {
  id: number
  order_id: number
  created_at: string
  updated_at: string
  discount_percentage: number | null
  applied_credits: number
  tax_rate: number | null
  total_cost: number | null
  credit_subtracted_cost: number
  discounted_cost: number | null
  tax_cost: number | null
  eu_reverse_charge: boolean | null
  remaining_cost: number
  cost_per_service: {
    quantity: number
    total_cost: number
    price: Price
  }[]
}

export interface Invoice {
  id: number
  number: number
  organization_id: number
  created_at: string
  updated_at: string
}

export type OrderStatus = 'failed' | 'canceled' | 'expired' | 'pending' | 'open' | 'paid' | 'chargeback' | 'refunded'

export interface OrderPayment {
  id: number
  order_id: number
  mollie_payment_id: string | null
  status: OrderStatus
  amount: number
  amount_refunded: null | number
  amount_charged_back: null | number
  discount: number
  created_at: string
  updated_at: string
}

export type PaymentMethodType = 'creditcard' | 'directdebit' | 'invoice'

export interface BasePaymentMethod {
  id: number
  organization_id: number
  type: PaymentMethodType
  status: 'valid' | 'pending' | 'invalid' | ''
  mollie_payment_id: null | string
  created_at: string
  updated_at: string
}

export interface CreditcardPaymentMethod extends BasePaymentMethod {
  type: 'creditcard'
  details?: {
    cardExpiryDate: string
    cardFingerprint: string
    cardHolder: string
    cardLabel: string
    cardNumber: string
  }
  payment_url?: string
  payment_status?: string
}

export interface DirectdebitPaymentMethod extends BasePaymentMethod {
  type: 'directdebit'
  details?: {
    consumerName: string
    consumerAccount: string
  }
}

export interface InvoicePaymentMethod extends BasePaymentMethod {
  type: 'invoice'
}

export type PaymentMethod = CreditcardPaymentMethod | DirectdebitPaymentMethod | InvoicePaymentMethod

export type HighlightTarget = string | RegExp | (string | RegExp)[]

export interface PersonalAccessToken {
  name: string
  id: number
  created_at: string
  updated_at: string
  last_used_at: string | null
  abilities: string[]
}

export type ReportType = 'case' | 'cases' | 'preview' | 'client' | 'organization-audit' | 'user-sessions'

export interface Report {
  id: number
  name: string
  user_id: number
  organization_id: number
  type: ReportType
  created_at: string
  updated_at: string
  batch_id: string | null
}

export interface ReportSchedule {
  id: number
  user_id: number
  reportable_id: number
  created_at: string
  updated_at: string
  scheduled_at: string
}

export interface ReportSettings {
  id: number
  user_id: number
  type: ReportType
  pages: string[]
  detailed: boolean | null
  include_case_reports: boolean | null
  case_report_pages: string[] | null
}

export interface Session {
  id: number
  ip_address: string
  last_activity: number
  user_agent: string
  user_id: number
  browser: {
    name: string
    version: string
    os: string
  }
}

export type SortOrder = 'asc' | 'desc' | ''

export interface SortEvent {
  prop: string
  order: SortOrder
}

export interface DashboardPanelPosition {
  h: number
  w: number
  x: number
  y: number
}

export interface DashboardPanel extends DashboardPanelPosition {
  id: number
  dashboard_id: number
  title: string
  metric: string
  chart_type: string
  time_from_days: number | null
  group_id: number[] | null
  client_id: number[] | null
  created_at: string
  updated_at: string
}

export interface Dashboard {
  id: number
  index: number
  user_id: number
  title: string
  panels: DashboardPanel[]
  group_id: number[] | null
  client_id: number[] | null
  time_from_days: number | null
  created_at: string
  updated_at: string
}

export interface Settings {
  application_update_email: boolean
  date_format: string
  default_confidence: Confidence
  interface_language: Locale
  known_languages: string[]
  notify_hit_updates: string
  send_empty_monitor_report: boolean
  summary_report_day_of_week: number
  summary_report_email: boolean
  summary_report_interval: string
  translation_language: string
  require_one_time_password: boolean
}

/**
 * This allows for autocompletion on literal strings when combined with literal strings.
 * A normal 'foo' | string does not work. But 'foo' | SomeOtherString works.
 */
export type SomeOtherString = string & Record<never, never>

/**
 * Construct a type which is a subset of `T`, containing at least all attributes of union `K`.
 *
 * @example
 * // Correct:
 * const test: AtLeast<{id: number, name: string, age: number}, 'id' | 'name'> = { id: 1 }
 * // Shows error:
 * const test: AtLeast<{id: number, name: string, age: number}, 'id' | 'name'> = { id: 1, new: true }
 * const test: AtLeast<{id: number, name: string, age: number}, 'id' | 'name'> = { age: 1 }
 */
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

/**
 * Construct a type which is a subset of `T`, where all attributes of `K` are optional.
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

/**
 * Construct a type which extends`T` with overwritten properties defined in `U`
 *
 * @example
 * type NameIsRequired = Overwrite<{id: number; name: string | null }, { name: string }>
 */
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U
