import { keyBy } from '@vartion/ui'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { route } from '~/router.ts'
import type { CreditcardPaymentMethod, PaymentMethod, PaymentMethodType } from '~/types.ts'

interface CreatePaymentMethodRequest {
  type: PaymentMethodType
  consumer_name?: string
  consumer_account?: string
  organization_name?: string
}

export function createPaymentMethodStore(name: string) {
  return defineStore(name, () => {
    // Public state
    const isEditing = ref(false)
    const loading = ref(false)

    // Private state
    const paymentMethods = ref<PaymentMethod[]>([])
    const organizationId = ref(0)
    const organizationName = ref('')
    const signupToken = ref('')

    const paymentMethod = computed(() => paymentMethods.value.find((paymentMethod) => paymentMethod.status === 'valid'))
    const paymentMethodsByType = computed(
      (): Partial<{
        creditcard: CreditcardPaymentMethod
        directdebit: CreditcardPaymentMethod
        invoice: CreditcardPaymentMethod
      }> => keyBy('type', paymentMethods.value),
    )
    const payByInvoiceAllowed = computed({
      get: () => 'invoice' in paymentMethodsByType.value,
      set: (value) => togglePayByInvoiceAllowed(value),
    })
    const isValid = computed(() => paymentMethod.value?.status === 'valid')
    const baseRequest = computed(() => ({
      signup_token_uuid: signupToken.value || undefined,
      organization_name: organizationName.value || undefined,
      organization_id: organizationId.value || undefined,
    }))

    function setOrganization({ token, name, id }: { token?: string; name?: string; id?: number }) {
      organizationId.value = id ?? 0
      signupToken.value = token ?? ''
      organizationName.value = name ?? ''
    }

    async function fetch() {
      loading.value = true
      try {
        const { data } = await api.post<PaymentMethod[]>('payment-methods/searches', baseRequest.value)
        paymentMethods.value = data

        if (paymentMethod.value) {
          stores.organization.invalid_payment_method_at = null
        }
      } catch {
        paymentMethods.value = []
      } finally {
        loading.value = false
      }
    }

    async function create(form: CreatePaymentMethodRequest) {
      loading.value = true
      try {
        let redirectPath = route.value.fullPath
        if (redirectPath.includes('?')) {
          redirectPath += '&paid=true'
        } else {
          redirectPath += '?paid=true'
        }

        await api.post<PaymentMethod>('payment-methods', {
          ...baseRequest.value,
          ...form,
          redirect_path: redirectPath,
        })

        await fetch()
      } finally {
        loading.value = false
      }
    }

    async function update(type: PaymentMethodType, status: string) {
      loading.value = true
      try {
        const id = paymentMethods.value.find((paymentMethod) => paymentMethod.type === type)?.id
        await api.patch<PaymentMethod>(`payment-methods/${id}`, {
          ...baseRequest.value,
          status,
        })

        await fetch()
      } finally {
        loading.value = false
      }
    }

    async function remove(type: string) {
      loading.value = true
      try {
        const paymentMethodId = paymentMethods.value.find((paymentMethod) => paymentMethod.type === type)?.id
        if (paymentMethodId) {
          await api.delete(`payment-methods/${paymentMethodId}`)
          findAndSpliceArray(paymentMethods.value, (paymentMethod) => paymentMethod.type === type)
        }
      } finally {
        loading.value = false
      }
    }

    function togglePayByInvoiceAllowed(allowed = !payByInvoiceAllowed.value) {
      if (allowed === payByInvoiceAllowed.value) return

      if (allowed) {
        create({ type: 'invoice' })
      } else {
        remove('invoice')
      }
    }

    return {
      loading,
      isEditing,
      paymentMethod,
      paymentMethodsByType,
      payByInvoiceAllowed,
      isValid,
      setOrganization,
      fetch,
      create,
      update,
      remove,
      togglePayByInvoiceAllowed,
    }
  })
}

export const usePaymentMethod = createPaymentMethodStore('paymentMethod')
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaymentMethod, import.meta.hot))
}
