import { Userpilot } from 'userpilot'

import { type Env, type UserDetailed } from '~/types.ts'

export const userpilotClient = {
  env: {} as Env['userpilot'],

  setUser(user: UserDetailed) {
    if (Object.keys(this.env).length === 0) {
      throw Error('Please make sure that userpilot is initialized first!')
    }

    let isLegacyUser = false

    if (this.env.legacy_date) {
      isLegacyUser = dayjs(user.created_at).isBefore(dayjs(this.env.legacy_date))
    }

    Userpilot.identify(`${$env.app_name} ${user.id}`, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      isLegacyUser,
      roles: user.roles.map((role) => role.name),
      company: {
        id: stores.organization.id,
      },
    })
    Userpilot.reload()
  },

  run(env: Env['userpilot']) {
    this.env = env

    if (env.token) {
      Userpilot.initialize(env.token)
    }
  },

  reload() {
    Userpilot.reload()
  },
}
